<template>
  <div> 
    <NavComp/>
    <div class="container">
      <h1 class="heading">Profilepage</h1>
      <p class="heading is-danger">Funktioniert noch nicht!</p>
      <!-- 
        firstname
        surname
        geb date
        change email adress
        saved location
        enable notifications
      -->
      <form>
          <label for="firstname" class="label">Firstname</label>
          <div class="control">
              <input id="firstname" type="text" v-model="firstname" class="input"/>
              <p class="help is-danger">{{ firstname_error }}</p>
          </div>
          <label for="lastname" class="label">Lastname</label>
          <div class="control">
              <input id="lastname" type="text" v-model="lastname" class="input"/>
              <p class="help is-danger">{{ lastname_error }}</p>
          </div>
          <br>
          <b-field label="Select your brithday">
            <b-datepicker
                v-model="geb_date"
                :locale="location"
                placeholder="Click to select..."
                icon="calendar-today"
                trap-focus>
            </b-datepicker>
          </b-field>
          <br>
          <br>
          <label for="lastname" class="label">Change e-mail</label>
          <div class="control">
              <input id="emailadress" type="email" v-model="email" class="input"/>
              <p class="help is-danger">{{ email_error }}</p>
          </div>
          <p class="help is-danger">{{ error }}</p>
          <button class="button is-info top-margin" @click="save_properties">Save</button>
      </form>
    </div>
  </div>
</template>

<script>
import NavComp from "@/components/NavComp.vue"

export default {
    name: "Profile",
    components: {
      NavComp
    },
    data() {
      return {
        //models
        firstname: "",
        lastname: "",
        email: "",
        geb_date: new Date(),
        location: undefined,//this is browser locale //"de-DE", //American option: "en-US"

        //errors
        firstname_error: "",
        lastname_error: "",
        error: "",
        email_error: "",
      }
    },
    methods: {
      save_properties: () => {
        console.log("properties saved!?");
      }
    }
}
</script>

<style scoped>
.heading { 
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  margin-top: 14px;
}
</style>